.card {
	border-radius: $border-radius-base;
	background-color: $white-color;
	margin-bottom: 30px;

	.card-image {
		width: 100%;
		overflow: hidden;
		height: 260px;
		border-radius: $border-radius-base $border-radius-base 0 0;
		position: relative;
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;

		img {
			width: 100%;
		}
	}
	.filter {
		position: absolute;
		z-index: 2;
		background-color: rgba(0, 0, 0, 0.68);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;

		@include opacity(0);

		.btn {
			@include vertical-align();
		}
	}
	&:hover .filter {
		@include opacity(1);
	}
	.btn-hover {
		@include opacity(0);
	}
	&:hover .btn-hover {
		@include opacity(1);
	}
	.card-body {
		padding: 15px 15px 0px 15px;
	}
	.card-header {
		padding: 15px 15px 0;
		background-color: $white-color;
		border-bottom: none !important;
	}
	.card-category,
	label {
		font-size: $font-size-base;
		font-weight: $font-weight-normal;
		color: $dark-gray;
		margin-bottom: 0px;

		i {
			font-size: $font-paragraph;
		}
	}

	label {
		font-size: $font-size-small;
		margin-bottom: 5px;
		text-transform: uppercase;
	}

	.card-title {
		margin: $none;
		color: $black-color;
		font-weight: $font-weight-light;
	}
	.avatar {
		width: 30px;
		height: 30px;
		overflow: hidden;
		border-radius: 50%;
		margin-right: 5px;
	}
	.description {
		font-size: 16px;
		color: #333;
	}
	.card-footer {
		padding-top: 0;
		background-color: $transparent-bg;
		line-height: 30px;
		border-top: none !important;
		font-size: 14px;

		.legend {
			padding: 5px 0;
		}

		hr {
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}
	.stats {
		color: #a9a9a9;
	}
	.card-footer div {
		display: inline-block;
	}

	.author {
		font-size: $font-size-small;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
	}
	.author i {
		font-size: $font-size-base;
	}
	h6 {
		font-size: $font-size-small;
		margin: 0;
	}
	&.card-separator:after {
		height: 100%;
		right: -15px;
		top: 0;
		width: 1px;
		background-color: $medium-gray;
		card-body: '';
		position: absolute;
	}

	.ct-chart {
		margin: 30px 0 30px;
		height: 245px;
	}

	.ct-label {
		font-size: 1rem !important;
	}

	.table {
		tbody td:first-child,
		thead th:first-child {
			padding-left: 15px;
		}

		tbody td:last-child,
		thead th:last-child {
			padding-right: 15px;
		}
	}

	.alert {
		border-radius: $border-radius-base;
		position: relative;

		&.alert-with-icon {
			padding-left: 65px;
		}
	}
}

.card-stats {
	.card-body {
		padding: 15px 15px 0px;

		.numbers {
			font-size: 1.8rem;
			text-align: right;

			p {
				margin-bottom: 0;
			}
		}
	}
	.card-footer {
		padding: 0px 15px 10px 15px;
	}
	.icon-big {
		font-size: 3em;
		min-height: 64px;

		i {
			font-weight: 700;
			line-height: 59px;
		}
	}
}

.card-user {
	.card-image {
		height: 110px;
	}
	.card-image-plain {
		height: 0;
		margin-top: 110px;
	}
	.author {
		text-align: center;
		text-transform: none;
		margin-top: -70px;
	}
	.avatar {
		width: 124px;
		height: 124px;
		border: 5px solid #ffffff;
		position: relative;
		margin-bottom: 15px;

		&.border-gray {
			border-color: #eeeeee;
		}
	}
	.title {
		line-height: 24px;
	}
	.card-body {
		min-height: 140px;
	}
}

.card-user,
.card-price {
	.card-footer {
		padding: 5px 15px 10px;
	}
	hr {
		margin: 5px 15px;
	}
}
.card-plain {
	background-color: transparent;
	box-shadow: none;
	border-radius: 0;

	.card-image {
		border-radius: 4px;
	}
}

.card.card-plain {
	border: none !important;

	.card-header {
		background-color: transparent !important;
	}
}
